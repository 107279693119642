<template>
  <div class="vlive-popup" id="vlive_popup">
    <div
      class="popup-box"
      :class="radiusType === 'full' ? borderFull : radiusType === 'bottom' ? borderBottom : ''"
      :style="{ width, margin, height }"
      ref="popupBox"
    >
      <div class="popup-header" :class="radiusType === 'top' || radiusType === 'full' ? borderTop : ''">
        <div
          v-if="!remove && !showDarkClose"
          alt="closes"
          id="popup-close-icon"
          class="close-popup"
          @click="closePopupEvent()"
          :class="[localDisplayLang === 'ara' ? 'rev-close' : 'main-close']"
        >
        <closepopup></closepopup>
        </div>
        <div
          v-if="showDarkClose"
          class="close-popup"
          :class="[localDisplayLang === 'ara' ? 'rev-dark-close' : 'dark-close']"
          @click="closePopupEvent()"
          alt
        >
        <shadowclosepopup></shadowclosepopup>
      </div>
        <slot name="header"></slot>
      </div>
      <div class="popup-social">
        <slot name="social"></slot>
      </div>
      <div
        class="popup-component"
        :class="radiusType === 'bottom' ? borderBottom : radiusType === 'full' ? borderFull : ''"
      >
        <slot name="component"></slot>
        <Loading class="loader" v-if="loader"></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  name: "Popup",
  props: {
    closePopupEvent: {
      type: Function,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    margin: {
      type: String,
    },
    radiusType: {
      type: String,
    },
    remove: {
      type: String,
    },
    authUser: {
      type: String,
    },
    showDarkClose: {
      type: Boolean,
    },
  },
  data() {
    return {
      borderTop: "border-top",
      borderBottom: "border-bottom",
      borderFull: "border-full",
      loader: false,
      localDisplayLang: null,
    };
  },
  watch: {
    $route() {
      this.closePopupEvent();
    },
  },
  computed: {
    ...mapGetters(["getRtl"]),
  },
  mounted() {
    // let self = this;
    // let isScroll = true;

    // setTimeout(() => {
    //    let popup = document.getElementById("vlive_popup");
    //     popup.addEventListener("scroll", function() {
    //       let scrollHeight;
    //       let elementScrollHeight;

    //       scrollHeight = document.body.scrollHeight;
    //       elementScrollHeight = popup.scrollTop;

    //       if (elementScrollHeight >= scrollHeight) {
    //         if (isScroll) {
    //           eventBus.$emit("loadMore");
    //           isScroll = false;
    //         }
    //       }
    //     });
    // }, 2000);

    document.body.style.overflow = "hidden";

    eventBus.$on("loader", (state) => {
      this.loader = state;
    });
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
      };
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    closepopup: () => import(/* webpackChunkName: "Loading" */ "@/components/SvgImages/ClosePopup.vue"),
    shadowclosepopup: () => import(/* webpackChunkName: "Loading" */ "@/components/SvgImages/ShadowClosePopup.vue"),
  },
};
</script>

<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "./Popup.scss"
</style>